import { useQuery } from "@apollo/client";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  PaletteMode,
  Snackbar,
  ThemeProvider
} from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { OrderItemDto, Stock, UserDto } from "./api";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import GoogleRecaptcha from "./components/utils/GoogleRecaptcha";
import ScrollPositionRestore from "./components/utils/ScrollPositionRestore";
import { CART, COLOR_MODE, CONTENTFUL_DATA, LANGUAGE, NECESSARY_COOKIES } from "./constants/global";
import { routes } from "./constants/routes";
import { CartContext, CartContextSchema } from "./context/CartContext";
import { ColorContext } from "./context/ColorContext";
import { ContentfulContext } from "./context/ContentfulContext";
import { ErrorContext, ErrorContextSchema } from "./context/ErrorContext";
import { LanguageContext, LanguageContextSchema } from "./context/LanguageContext";
import { ProductsContext, ProductsContextSchema } from "./context/ProductsContext";
import { RecaptchaContext, RecaptchaContextSchema } from "./context/RecaptchaContext";
import { UserContext, UserContextSchema } from "./context/UserContext";
import { Language, LanguageCollection } from "./models/schema";
import LoginPage from "./pages/auth/LoginPage";
import PasswordChangePage from "./pages/auth/PasswordChangePage.";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import Cart from "./pages/Cart";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import PartnerPage from "./pages/PartnerPage";
import ProductPage from "./pages/ProductPage";
import ProductsPage from "./pages/ProductsPage";
import { authApi, stockApi } from "./querries/api";
import {
  GET_LANGUAGES,
  GET_LANGUAGE_GENERAL,
  GET_LANGUAGE_LOGO_AND_COLLECTIONS,
  GET_LANGUAGE_PAGES
} from "./querries/querries";
import { darkTheme } from "./theme/dark";
import { lightTheme } from "./theme/light";
import { getItem, setItem } from "./utils/storage";

type ContentfulData = {
  generalData: Maybe<Language>;
  logoAndCollections: Maybe<Language>;
  pages: Maybe<Language>;
};

const Content = ({ language }: { language: string }) => {
  const { data: generalData, loading: loadingGeneral } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGE_GENERAL, {
    variables: {
      code: language
    },
    fetchPolicy: "no-cache"
  });

  const { data: logoAndCollections, loading: loadingLogoAndCollections } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGE_LOGO_AND_COLLECTIONS, {
    variables: {
      code: language
    },
    fetchPolicy: "no-cache"
  });

  const { data: pages, loading: loadingPages } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGE_PAGES, {
    variables: {
      code: language
    },
    fetchPolicy: "no-cache"
  });

  const key = `${CONTENTFUL_DATA}_${language?.toUpperCase()}`;
  const [data, setData] = useState<ContentfulData>(getItem(key));
  const [content, setContent] = useState<Language>();

  useEffect(() => {
    if (generalData) {
      setData((prevState) => ({
        ...prevState,
        generalData: generalData?.languageCollection?.items[0]
      }));
    }

    if (logoAndCollections) {
      setData((prevState) => ({
        ...prevState,
        logoAndCollections: logoAndCollections?.languageCollection?.items[0]
      }));
    }

    if (pages) {
      setData((prevState) => ({
        ...prevState,
        pages: pages?.languageCollection?.items[0]
      }));
    }
  }, [generalData, logoAndCollections, pages]);

  useEffect(() => {
    if (data) {
      setItem(key, data);
    }

    if (data?.generalData && data?.logoAndCollections && data?.pages) {
      setContent({
        ...data.generalData,
        ...data.logoAndCollections,
        ...data.pages
      });
    }
  }, [data]);

  const { error, setError } = useContext(ErrorContext);

  if (loadingGeneral || loadingLogoAndCollections || loadingPages) {
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <CircularProgress size={100} />
    </Box>;
  }

  return (
    <>
      {content && (
        <ContentfulContext.Provider value={{ content: content as Language }}>
          <Router>
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minHeight: "100%",
                px: { xs: 4, md: 6 },
                overflowX: { xs: "hidden", md: "unset" }
              }}>
              <Header />
              <Box
                sx={{
                  flex: "1 0 auto",
                  flexDirection: "column",
                  display: "flex",
                  pt: { xs: 17, lg: 30 }
                }}>
                <ScrollPositionRestore />
                <Snackbar
                  open={!!error}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  autoHideDuration={6000}
                  onClose={() => setError(null)}>
                  <Alert
                    onClose={() => setError(null)}
                    severity="error"
                    sx={{
                      display: "flex",
                      alignItems: "center"
                    }}>
                    {content?.errorMessage as string}
                  </Alert>
                </Snackbar>
                <Routes>
                  {/* Auth */}
                  <Route path={routes.login} element={<LoginPage />}></Route>
                  <Route path={routes.passwordChange} element={<PasswordChangePage />}></Route>
                  <Route path={routes.passwordReset} element={<PasswordResetPage />}></Route>
                  {/* Products */}
                  <Route path={routes.home} element={<HomePage />}></Route>
                  <Route path={routes.products} element={<ProductsPage />}></Route>
                  <Route
                    path={`${routes.products}/${routes.ean}`}
                    element={<ProductPage />}></Route>
                  {/* Contact and partner */}
                  <Route path={routes.contact} element={<ContactPage />}></Route>
                  <Route path={routes.partner} element={<PartnerPage />}></Route>
                  {/* Cart */}
                  <Route path={routes.cart} element={<Cart />}></Route>
                  {/* Error handling */}
                  <Route path="*" element={<Navigate to={routes.home} replace />} />
                </Routes>
              </Box>
              <Footer />
            </Container>
          </Router>
        </ContentfulContext.Provider>
      )}
    </>
  );
};

const App = () => {
  // Theme

  const storedMode = getItem(COLOR_MODE) as PaletteMode;
  const [colorMode, setColorMode] = useState<PaletteMode>(storedMode || ("light" as PaletteMode));
  const theme = useMemo(() => (colorMode === "light" ? lightTheme : darkTheme), [colorMode]);

  const selectedColorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setColorMode((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
      }
    }),
    []
  );

  useEffect(() => {
    setItem(COLOR_MODE, colorMode);
  }, [colorMode]);

  // Contentful

  const { data: languageCollection } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGES);

  const storedLanguage = getItem(LANGUAGE);
  const [language, setLanguage] = useState<string | null>(storedLanguage);

  const languageContextData = useMemo(
    () =>
      ({
        selectedLanguage: language,
        availableLanguages: languageCollection?.languageCollection?.items?.filter(
          (lang) => !!lang?.active
        ) as Language[],
        setLanguage: (lang: string) => {
          setLanguage(lang);
        }
      } as LanguageContextSchema),
    [language, languageCollection?.languageCollection?.items]
  );

  useEffect(() => {
    if (!language) {
      const defaultLang =
        (languageCollection?.languageCollection?.items.find((lang) => lang?.default)
          ?.code as string) || (languageCollection?.languageCollection?.items[0]?.code as string);
      setLanguage(defaultLang);
    }
  }, [languageCollection, language]);

  useEffect(() => {
    if (language) {
      setItem(LANGUAGE, language);
    }
  }, [language]);

  // Errors

  const [error, setError] = useState<string | null>();

  const errorContextData = useMemo(
    () =>
      ({
        error,
        setError
      } as ErrorContextSchema),
    [error]
  );

  // User

  const [user, setUser] = useState<UserDto | undefined>();

  useEffect(() => {
    if (authApi) {
      authApi
        .login()
        .then((user) => {
          setUser(user);
        })
        .catch(() => {
          setUser(undefined);
        });
    }
  }, []);

  const userContextData = useMemo(
    () =>
      ({
        user,
        setUser
      } as UserContextSchema),
    [user]
  );

  // Cart

  const [cart, setCart] = useState<OrderItemDto[]>();

  const addItem = (item: OrderItemDto) =>
    setCart((prevCart) => {
      if (!prevCart) return [item];

      const itemToUpdateIndex = prevCart.map((i) => i.ean).indexOf(item.ean);

      if (itemToUpdateIndex === -1) {
        return [...prevCart, item];
      }

      if (item.quantity === undefined || item.quantity === null) return prevCart;

      const itemToUpdate = prevCart[itemToUpdateIndex];
      const newCart = [...prevCart];
      newCart[itemToUpdateIndex] = {
        ...itemToUpdate,
        quantity: itemToUpdate.quantity ?? 0 + item.quantity
      };

      return newCart;
    });

  const updateItem = (item: OrderItemDto) =>
    setCart((prevCart) => {
      if (!prevCart) return [item];

      const itemToUpdateIndex = prevCart.map((i) => i.ean).indexOf(item.ean);

      if (itemToUpdateIndex === -1) {
        return [...prevCart, item];
      }

      const itemToUpdate = prevCart[itemToUpdateIndex];
      const newCart = [...prevCart];
      newCart[itemToUpdateIndex] = {
        ...itemToUpdate,
        ...item
      };

      return newCart;
    });

  const removeItem = (ean: string) =>
    setCart((prevCart) => {
      if (!prevCart) return [];

      return prevCart.filter((item) => item.ean !== ean);
    });

  const resetCart = () => setCart([]);

  const cartContextData = useMemo(
    () =>
      ({
        cart,
        addItem,
        removeItem,
        updateItem,
        resetCart
      } as CartContextSchema),
    [cart]
  );

  useEffect(() => {
    if (user && cart) {
      setItem(CART, cart);
    }
  }, [cart, user]);

  useEffect(() => {
    if (user) {
      setCart(getItem(CART) ?? []);
    } else {
      setCart(undefined);
    }
  }, [user]);

  // Recaptha

  const ref = useRef<ReCAPTCHA | null>(null);
  const [neccessaryCookiesAllowed, setNeccessaryCookiesAllowed] = useState<boolean>(
    getItem(NECESSARY_COOKIES)
  );
  const [badgeVisible, setBadgeVisible] = useState<boolean>(false);
  const [recaptchaRef, setRecaptchaRef] = useState<MutableRefObject<ReCAPTCHA | null> | null>(null);

  const recaptchaContextData = useMemo(
    () =>
      ({
        badgeVisible,
        recaptchaRef,
        showBadge: () => setBadgeVisible(true),
        hideBadge: () => setBadgeVisible(false),
        neccessaryCookiesAllowed,
        setNeccessaryCookiesAllowed
      } as RecaptchaContextSchema),
    [badgeVisible, recaptchaRef, neccessaryCookiesAllowed]
  );

  useEffect(() => {
    if (ref) {
      setRecaptchaRef(ref);
    }
  }, [ref]);

  // Products

  const [products, setProducts] = useState<Stock[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadProducts = useCallback(() => {
    setLoading(true);

    stockApi
      .getAllStock({ stockFilter: {} })
      .then((response) => {
        setProducts(response);
        setLoading(false);
      })
      .catch((error) => {
        error.response.json().then((json: unknown) => {
          console.error(json);

          setLoading(false);
          setError(error);
        });
      });
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts, user]); // When user log in we need to refresh products to obtain personalized prices

  const productContextData = useMemo(
    () =>
      ({
        loading,
        products,
        reloadProducts: loadProducts
      } as ProductsContextSchema),
    [loading, products, loadProducts]
  );

  return (
    <ColorContext.Provider value={selectedColorMode}>
      <LanguageContext.Provider value={languageContextData}>
        <ProductsContext.Provider value={productContextData}>
          <CartContext.Provider value={cartContextData}>
            <ErrorContext.Provider value={errorContextData}>
              <UserContext.Provider value={userContextData}>
                <ThemeProvider theme={theme}>
                  <RecaptchaContext.Provider value={recaptchaContextData}>
                    <CssBaseline enableColorScheme />
                    {language && <Content language={language} />}
                    <GoogleRecaptcha recaptchaRef={ref} />
                  </RecaptchaContext.Provider>
                </ThemeProvider>
              </UserContext.Provider>
            </ErrorContext.Provider>
          </CartContext.Provider>
        </ProductsContext.Provider>
      </LanguageContext.Provider>
    </ColorContext.Provider>
  );
};

export default App;
